import React from "react"
import { graphql } from "gatsby"
import { Container } from 'components/library'
import { CtaBlock } from "components/common/cta-block"
import { OverviewFaqs } from "components/pages/products/overview/overview-faqs"
import { Hero } from "components/pages/products/overview/overview-hero"
import { AiEngine } from "components/pages/products/overview/overview-ai-engine"
import { ProductDevelopment } from "components/pages/products/overview/overview-product-development"
import { ManageYourData } from "components/pages/products/overview/overview-manage-your-data"
import { Rnd } from "components/pages/products/overview/overview-rnd"
import { CaseStudy } from "components/pages/products/overview/overview-case-study"

const ProductPage = () => {
  return (
    <main className="bg-product-hero-bg-mobile sm:bg-product-hero-bg bg-no-repeat bg-contain -mt-28 sm:-mt-36">
      <Container className='flex flex-col pt-44 sm:pt-60 pb-12 gap-20 sm:gap-32'>
        <Hero />
        <AiEngine />
        <ProductDevelopment />
        <ManageYourData />
        <Rnd />
        <CaseStudy />
        <OverviewFaqs className='py-12' />
      </Container>

      <CtaBlock />
    </main >
  )
}

export default ProductPage

// FOR LANGUAGE TRANSLATION
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`; 
